<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">更换手机号</div>
    </div>
    <div class="mobileSize">手机号码</div>
    <div class="mobileInp">
      <input type="text" placeholder="请输入手机号" v-model="form.mobile" />
    </div>
    <div class="codeSize">验证码</div>
    <div class="codeInp">
      <input type="text" placeholder="请输入验证码" v-model="form.code" />
      <div class="codeDiv" v-if="isShow" @click="obtainCode()">获取验证码</div>
      <div class="codeDiv1" v-if="!isShow">
        <span>{{ this.count }}s</span>重新获取
      </div>
    </div>
    <div class="footerBtn" @click="goMobilsuccess()">确定</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { setMobile } from "../../../api/circle";
import { send } from "../../../api/user";
export default {
  data() {
    return {
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      form: {
        mobile: "", //手机号
        code: "", //验证码
      },
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 获取验证码
    obtainCode() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.form.mobile)) {
        Toast("请输入正确的手机号");
      } else {
        send({ mobile: this.form.mobile, type: 4 }).then((res) => {
          console.log(res, "登录获取验证码");
          if (res.data.code != 0) {
            Toast(res.data.msg);
          } else {
            this.isShow = false;
            this.count = 5;
            var times = setInterval(() => {
              this.count--; //递减
              if (this.count <= 0) {
                // <=0 变成获取按钮
                this.isShow = true;
                clearInterval(times);
              }
            }, 1000); //1000毫秒后执行
          }
        });
      }
    },
    goMobilsuccess() {
      setMobile(this.form).then((res) => {
        console.log(res, "更换手机号");
        if (res.data.code === 0) {
          this.$router.push({
            path: "/mobilsuccess",
            query: { mobile: this.form.mobile },
          });
          this.$store.state.user.mobile = res.data.data.mobile;
          // this.$store.commit('mobile',res.data.data.mobile)
        } else {
          Toast(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 228px;
}
.mobileSize,
.codeSize {
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  margin-left: 32px;
  margin-top: 58px;
}
.mobileInp input {
  width: 686px;
  height: 92px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  border: none;
  margin-top: 20px;
  margin-left: 32px;
  padding-left: 24px;
  box-sizing: border-box;
}
.codeSize {
  margin-top: 32px;
}

.codeInp {
  width: 686px;
  height: 92px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  border: none;
  margin-top: 20px;
  margin-left: 32px;
}
.codeInp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.codeInp input {
  border: none;
  background: #f6f8fa;
  margin-left: 24px;
}
.codeDiv {
  margin-right: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}
.codeDiv1 {
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  margin-right: 32px;
}
.codeDiv1 span {
  color: #0957c3;
}
.footerBtn {
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 88px;
  position: fixed;
  bottom: 52px;
  left: 32px;
}
</style>
